































































import Vue from 'vue';
import EmailHTMLEditor from './component/EmailHTMLEditor.vue';

export default Vue.extend({
  components: { EmailHTMLEditor },
  data: () => {
    return {
      headers: [
        {
          text: 'Name',
          align: 'center',
          value: 'name',
          divider: true,
          sortable: false,
        },
        { text: 'Value($)', align: 'center', value: 'value' },
        {
          text: 'Interval',
          align: 'center',
          value: 'interval',
          sortable: false,
          filterable: true,
        },
        { text: 'Subscriptors', align: 'center', value: 'subscriptors' },
        { text: 'Active', align: 'center', value: 'active', sortable: false },
        {
          text: 'Trial',
          align: 'center',
          value: 'trial.enable',
          sortable: false,
        },
        {
          text: 'Action',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
      listPackages: [],
      showCreateModal: false,
      showUpdateModal: false,
      notifyBar: {
        show: false,
        text: '',
      },
      pkgToUpdate: {},
    };
  },
  computed: {
    dialogResize() {
      let width = '30%';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          width = '100%';
          break;
        case 'sm':
          width = '100%';
          break;
        case 'md':
          width = '80%';
          break;
        default:
          width = '60%';
          break;
      }
      return width;
    },
  },
  methods: {},
});
