import { INavComponent } from './interface/NavBar.interface';

const navComponentsList: INavComponent[] = [
  {
    name: 'Dashboard',
    routes: 'dashboard',
    icon: 'mdi-view-dashboard',
  },
  {
    name: 'Package',
    routes: 'payment-package',
    icon: 'mdi-package-variant-closed',
  },
  {
    name: 'Customer',
    routes: 'customer',
    icon: 'mdi-account-group',
  },
  {
    name: 'Bot Settings',
    routes: 'bot-settings',
    icon: 'mdi-android',
  },
  {
    name: 'Mailer',
    routes: 'mailer',
    icon: 'mdi-email',
  },
];

export default navComponentsList;
