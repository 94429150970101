<template>
  <v-container class="pa-2 ma-0" fluid>
    <!-- <v-btn @click="saveDesign">Save Design</v-btn>
    <v-btn @click="exportHtml">Export HTML</v-btn> -->

    <EmailEditor
      class="emailEditor"
      ref="emailEditor"
      v-on:load="editorLoaded"
    />
  </v-container>
</template>

<script>
import { EmailEditor } from 'vue-email-editor';

export default {
  name: 'app',
  components: {
    EmailEditor,
  },
  methods: {
    editorLoaded() {
      // Pass the template JSON here
      // this.$refs.emailEditor.editor.loadDesign({});
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign(design => {
        console.log('saveDesign', design);
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml(data => {
        console.log('exportHtml', data);
      });
    },
  },
};
</script>
<style scoped>
.emailEditor {
  height: 80vh;
}
</style>
